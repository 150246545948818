import React from 'react';
import logo from './logo.svg';
import logo1 from './profil.png';
import './App.css';
import fabiheader from './fabiheader.js';

function sayHello() {
  window.location='https://api.whatsapp.com/send/?phone=%2B4915224120273&text=hello&type=phone_number&app_absent=0';
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <img src={logo1}  alt="logo1" />
      <br />
      <button onClick={sayHello}>contact</button>
      </header>
    </div>
  );
}

export default App;
