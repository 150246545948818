import React from 'react';
import logo1 from './profil.png';

class fabiheader extends React.Component {
    render() {
      return (
        <div className="fabiheader">
          <h1>header List for {this.props.name}</h1>
        </div>
      );
    }
  }

export default fabiheader;